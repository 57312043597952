import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import GridExample from "../components/GridExample";
import { Grid, GridCell, Box } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid"
    }}>{`Grid`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Grid>\n  <GridCell width={{ xs: 1, md: 1 / 2, xl: 1 / 3 }}>\n    <GridExample>\n      Full width mobile, half width tablet, 1/3 bigger\n    </GridExample>\n  </GridCell>\n  <GridCell width={{ xs: 1, md: 1 / 2, xl: 1 / 3 }}>\n    <GridExample>\n      Full width mobile, half width tablet, 1/3 bigger\n    </GridExample>\n  </GridCell>\n  <GridCell width={{ xs: 1, md: 1, xl: 1 / 3 }}>\n    <GridExample>\n      Full width mobile, full width tablet, 1/3 bigger\n    </GridExample>\n  </GridCell>\n  <GridCell width={[1 / 2]}>\n    <GridExample>Half width everywhere</GridExample>\n  </GridCell>\n  <GridCell width={[1 / 2]}>\n    <GridExample>Half width everywhere</GridExample>\n  </GridCell>\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      GridExample,
      Grid,
      GridCell,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid mdxType="Grid">
    <GridCell width={{
          xs: 1,
          md: 1 / 2,
          xl: 1 / 3
        }} mdxType="GridCell">
      <GridExample mdxType="GridExample">
        Full width mobile, half width tablet, 1/3 bigger
      </GridExample>
    </GridCell>
    <GridCell width={{
          xs: 1,
          md: 1 / 2,
          xl: 1 / 3
        }} mdxType="GridCell">
      <GridExample mdxType="GridExample">
        Full width mobile, half width tablet, 1/3 bigger
      </GridExample>
    </GridCell>
    <GridCell width={{
          xs: 1,
          md: 1,
          xl: 1 / 3
        }} mdxType="GridCell">
      <GridExample mdxType="GridExample">
        Full width mobile, full width tablet, 1/3 bigger
      </GridExample>
    </GridCell>
    <GridCell width={[1 / 2]} mdxType="GridCell">
      <GridExample mdxType="GridExample">Half width everywhere</GridExample>
    </GridCell>
    <GridCell width={[1 / 2]} mdxType="GridCell">
      <GridExample mdxType="GridExample">Half width everywhere</GridExample>
    </GridCell>
  </Grid>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <p>{`Grid is extended from `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`, while GridCell is extended from `}<inlineCode parentName="p">{`Box`}</inlineCode>{`.`}</p>
    <Props of={Grid} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      